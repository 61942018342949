import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import SplitType from 'split-type';

export const animateHomeSections = () => {
    const tl = gsap.timeline();
    const blurRadius = '10px';

    const sections = gsap.utils.toArray('.below-fold');

    sections.forEach((section) => {
      const headingLines = new SplitType(section.querySelector('h2'), {
        types: 'lines',
      });

      const subhead = section.querySelector('.section__subhead');
      const blurb = section.querySelector('.section__blurb');

      // IMPROVEMENT: Move to Animation library
      headingLines?.lines?.forEach((headingLine) => {
        tl.fromTo(
          headingLine,
          {
            y: '100%',
          },
          {
            y: '0%',
            duration: 1,
            stagger: {
              each: 0.5,
            },
            scrollTrigger: {
              trigger: headingLine,
              start: 'top bottom',
              end: 'top center-=50',
              scrub: 0.8,
              once: true,
            },
          }
        );
      });

      tl.fromTo(
        subhead,
        {
          opacity: 0,
          webkitFilter: `brightness(30%) blur(${blurRadius})`,
          filter: `brightness(30%) blur(${blurRadius})`,
          willChange: 'filter',
        },
        {
          opacity: 1,
          webkitFilter: 'brightness(100%) blur(0px)',
          filter: 'brightness(100%) blur(0px)',
          duration: 0.5,
          scrollTrigger: {
            trigger: subhead,
            start: 'top bottom',
            end: 'top center',
            scrub: 0.8,
            once: true,
          },
        }
      );

      tl.fromTo(
        blurb,
        {
          opacity: 0,
          webkitFilter: `brightness(30%) blur(${blurRadius})`,
          filter: `brightness(30%) blur(${blurRadius})`,
          willChange: 'filter',
        },
        {
          opacity: 1,
          webkitFilter: 'brightness(100%) blur(0px)',
          filter: 'brightness(100%) blur(0px)',
          duration: 0.5,
          scrollTrigger: {
            trigger: blurb,
            start: 'top bottom',
            end: 'top center',
            scrub: 0.8,
            once: true
          },
        }
      );
    });
}