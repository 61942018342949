interface ListItem {
    element: HTMLElement;
    button: HTMLButtonElement;
    content: HTMLElement;
}

export class ListManager {
    private items: ListItem[] = [];
    private activeClass = 'active';
    private listSelector = '.list__heading li';
    private buttonSelector = 'button';
    private contentSelector = '.list__content';

    constructor() {
        this.init();
    }

    private init(): void {
        this.cacheElements();
        this.addEventListeners();
    }

    private cacheElements(): void {
        const listItems = document.querySelectorAll(this.listSelector);
        listItems.forEach((item: HTMLElement) => {
            const button = item.querySelector(this.buttonSelector) as HTMLButtonElement;
            const content = item.querySelector(this.contentSelector) as HTMLElement;

            if (button && content) {
                this.items.push({ element: item, button, content })
            }
        })
    }

    private addEventListeners(): void {
        document.addEventListener('click', this.handleClick.bind(this));
    }

    private handleClick(event: Event): void {
        const target = event.target as HTMLElement;
        const button = target.closest(this.buttonSelector) as HTMLButtonElement;

        if (button) {
            const item = this.items.find(item => item.button === button);
            if (item) {
                this.toggleItem(item)
            }
        }
    }

    private toggleItem(item: ListItem): void {
        const isExpanded = item.element.classList.toggle(this.activeClass);
        this.setAriaExpanded(item.button, isExpanded)

        if (isExpanded) {
            this.expandItem(item);
            this.closeOtherItems(item);
        } else {
            this.closeItem(item);
        }
    }

    private expandItem(item: ListItem): void {
        const { content } = item;
        const itemContentHeight = content.scrollHeight;

        this.setPadding(content, 'var(--gutter-xs)');
        content.style.height = `${itemContentHeight}px`;
    }

    private setPadding(listContent: HTMLElement, paddingAmount: string): void {
        listContent.style.paddingTop = `${paddingAmount}`;
        listContent.style.paddingBottom = `${paddingAmount}`;
    }

    private closeItem(item: ListItem): void {
        const { content } = item;
        content.style.height = `0px`;
        this.setPadding(content, '0px');
    }

    private closeOtherItems(currentItem: ListItem): void {
        this.items.forEach(item => {
            if (item !== currentItem && item.element.classList.contains(this.activeClass)) {
                this.closeItem(item);
                item.element.classList.remove(this.activeClass);
                this.setAriaExpanded(item.button, false);
            }
        })
    }

    private setAriaExpanded(button: HTMLButtonElement, expanded: boolean): void {
        button.setAttribute('aria-expanded', expanded.toString())
    }
}
